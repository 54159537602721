@import '../../../stylesheets/shared/content/fonts.postcss';
@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.PublicCalendar {
  position: relative;
  height: 600px;
  padding: 1rem 0;

  :global(.rbc-month-view),
  :global(.rbc-btn-group) {
    background: white;
  }

  :global(.rbc-toolbar) button {
    @mixin gordita-bold;
    text-transform: uppercase;
    font-size: 0.65rem;
    &:active, &:focus {
      outline: none;
    }
  }

  :global(.rbc-toolbar-label) {
    @mixin gordita-bold;
    font-size: 1.5rem;
  }

  :global(.rbc-event) {
    background: $blue;
    color: white;
    font-size: 0.875rem;
    padding: 1px 4px;
    border-radius: 2px;
  }

  :global(.rbc-toolbar) {
    display: block;
  }

  :global(.rbc-btn-group) {
    float: right;
  }

  :global(.rbc-date-cell) {
    a {
      font-size: 0.75rem;
      color: inherit !important;
    }
  }

  :global(.rbc-today) {
    background: #edfbff;
  }

}
