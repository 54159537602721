@import '../../../stylesheets/shared/content/fonts.postcss';
@import '../../../stylesheets/utils/mixins.postcss';
@import '../../../stylesheets/variables.postcss';

.Events_controls {
  align-items: center;
  display: flex;
  justify-content: space-between;

  h3 {
    margin-bottom: 0;
  }

  .Events_modeControls {
    display: none;

    @mixin above $medium {
      display: block;
    }
  }
}

.Event {
  position: relative;
  background: white;
  border: 1px solid $mediumGrey;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  overflow: hidden;
  border-radius: 4px;

  :global(.icon) {
    vertical-align: middle;
    font-size: 1rem;
    color: #A9ACB5;
    display: inline-block;
    margin-right: 0.5rem;
  }
}

.Popup_Event {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(255,255,255,0.6);
}

.Popup_Event .Event {
  position: absolute;
  width: 315px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  border: 1px solid #ccc;

  .Event_truckImage {
    display: none;
  }
}

.Event_header {
  display: flex;
  line-height: 1;

  &:not(:last-child) {
    border-bottom: 1px solid $mediumGrey;
  }

  & > a {
    flex: 1 1 100%;
  }
}

.Event_location {
  line-height: 1.5;
  padding: 1rem;

  &:hover {
    .Event_locationName,
    .Event_locationAddress, {
      color: $blue;
    }

    :global(.u-strong) {
      color: $black;
    }
  }
}

.Event_locationName {
  @mixin gordita-medium;
  font-size: 0.9375rem;
  color: $black;
  display: inline-block;
  margin-right: 2rem;
  transition: color $hover-speed;

  :global(.icon-star) {
    color: $yellow;
    font-size: 1rem;
    display: inline-block;
    margin-right: 0.25rem;
  }
}

.Event_locationAddress {
  font-size: 0.875rem;
  vertical-align: middle;
  display: inline-block;
  color: $black;
  transition: color $hover-speed;

  :global(.icon) {
    color: #A9ACB5;
    font-size: 1rem;
    vertical-align: middle;
    margin-right: 0.3rem;
    position: relative;
    top: 0.05rem;
  }
}

.Event_title {
  border-bottom: 1px solid $mediumGrey;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  vertical-align: middle;
  padding: 0 1rem;

  @mixin above $small {
    display: block;
  }

  .Event_timeDay,
  .Event_timeHours {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
  }
}

.Event_time {
  display: flex;

  > div {
    flex: 1 1 50%;
  }

  .Event_timeHours {
    border-left: 1px solid $mediumGrey;
    border-bottom-width: 0;
    padding-left: 1rem;
  }
}

.Event_timeDay {
  margin-right: 1rem;
}

.Event_timeHours {
  border-bottom: 1px solid $mediumGrey;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.Event_noTrucks {
  @mixin h6;
  font-size: 0.7rem;
  margin-top: 0;
  padding: 0.75rem 1rem;
}

.Event_truck {
  background: white;
  display: flex;
  align-items: center;

  &:not(:first-child) {
    border-top: 1px solid $mediumGrey;
  }

  &__checked {
    background: $yellow !important;
  }
}

.Event_truckLink {
  display: flex;
  flex: 1 1 100%;
  align-items: center;

  &:last-child {
    border: none;
  }

  &:hover {
    .Event_truckName {
      color: $blue;
    }

    .Event_truckImage {
      opacity: 0.85;
    }
  }
}

.Event_truckText {
  flex: 1 1 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.Event_truckImage {
  flex: 1 1 180px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  transition: opacity $hover-speed;
  height: 70px;

  @mixin above $small {
    height: 80px;
  }
}

.Event_truckName {
  @mixin gordita-medium;
  font-size: 1.125rem;
  color: $black;
  display: block;
  margin: 0;
  line-height: 1.2;
  margin-bottom: 0.25rem;
  transition: color $hover-speed;
}

.Event_truckCategories {
  font-size: 0.875rem;
  display: block;
  color: $darkGrey;
  margin: 0;
  line-height: 1;
}

.EventNav {
  @mixin gordita-medium;
  display: flex;
  font-size: 0.75rem;
  height: 40px;
  justify-content: space-between;
  text-align: center;

  > div {
    padding: 0.35rem 0;
  }

  &_back, &_next {
    border: 1px solid $mediumGrey;
    cursor: pointer;
    transition: border-color $hover-speed;
    width: 164px;

    &:hover {
      border-color: $darkGrey;
    }
  }
}
